
// Content styles
.block-text,
.biography {
	line-height: 1.7;
	color: $color-tertiary;

	h1, h2, h3, h4, h5, h6 {
		color: $color-tertiary;
	}
	
	// Default paragraphs
	p {
		margin: 0 0 $paragraph-margin;

		&.intro {
			padding: 0 0 $paragraph-margin;
			border-bottom: $border-size $border-style $color-tertiary;
			color: $color-tertiary;
			font-family: $font-family-body;
			font-size: $font-size-body + 0.2;
			
			@media #{$mobileXL} {
				font-size: $font-size-body + 0.2;
			}
		}
	}

	// reset any <ul> that will appear in a template here
	// maintain vertical rhythm with paragraph margin
	ul[class] {
		@extend %reset-ul;
		margin: 0 0 $paragraph-margin;
	}
	
	// New default <ul> lists
	ul {
		@extend %default-ul;
	}
	
	// New default <ol> lists
	ol {
		@extend %default-ol;
	}
}

.type-secure {

	.block-text {
		color: $color-primary;

		a {
			color: $color-primary;

			&:hover {
				color: $color-secondary;
			}
		}
	}

	h1, h2, h3, h4, h5, h6 {
		color: $color-black;
	}
}

