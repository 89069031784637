
//==========  List styles  ==========//

// list item styles
%default-list {
    padding: 0 0 0 $block-padding;
    margin: 0 0 $paragraph-margin;
}

%default-ul {
    list-style: disc;
    @extend %default-list;
}

%default-ol {
    list-style: decimal;
    @extend %default-list;
}

// list item reset
%reset-ul {
    list-style: none;
    padding: 0;
    margin: 0;
}


//==========  Buttons and default styles  ==========//

// base button styles
// extend for different button styles
%button {
    border: none;
    // @include if( $border-radius, ( border-radius: $border-radius ) );
    background: $color-primary;
    background: linear-gradient(135deg, rgba(224,187,87,1) 0%, rgba(200,152,19,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e0bb57', endColorstr='#c89813', GradientType=1 );
    color: $color-white;
    font-size: $font-size-body - 0.4;
    font-weight: $font-bold;
    text-align: center;
    text-transform: uppercase;
    line-height: 1;
    transition: $transition;
    transition: t(link) ease-in-out;
}

%hover {
    background: $color-secondary;
    background: linear-gradient(135deg, rgba(44,43,44,1) 0%, rgba(12,13,18,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2c2b2c', endColorstr='#0c0d12', GradientType=1 );
    color: $color-white;
    transition: $transition;
    transition: t(link) ease-in-out;
}

// extend for default .read-more
%read-more {
    @extend %button;
    display: inline-block;
    padding: ($block-padding * 1/3) ($block-padding * 2/3);

    &:hover {
        @extend %hover;
    }
}


//==========  Pager styles  ==========//

%bx-pager {

    .bx-pager-item {
        display: inline-block;
    }

    .bx-pager-link {
        display: block;
        width: 11px;
        height: 11px;
        text-indent: -9999px;
        background: $color-white;
        border-radius: 50%;
        padding: 0;
        position: relative;
        margin-top: $block-padding * 1.5;
        margin-right: 6px;
        box-shadow: 0 0 3px -1px $color-black;

        &:hover {
            background: $color-primary;
        }

        &.active {
            background: $color-primary;

            &:hover {
                background: $color-primary-dark;
            }
        }

        span {
            @extend .hidden;
        }
    }
    
}
